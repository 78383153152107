import dayjs, { Dayjs } from "dayjs";
import { status } from "../models/Delivery.model";

class DeliveriesFilterInfo {
    archived: boolean = false;

    projectIds: number[] = [];

    startDate: Dayjs | null = null;
    endDate: Dayjs | null = null;

    dropOffAreaUuids: string[] = [];
    waypointUuids: string[] = [];
    equipmentIds: number[] = [];

    tradeIds: number[] = [];
    vendorIds: number[] = [];

    status: status[] = [];
    textSearch: string = "";

    constructor() {
        this.archived = false;

        this.projectIds = [];

        // Default to 6 weeks of data
        this.startDate = dayjs().startOf("month").startOf("week");
        this.endDate = dayjs().endOf("month").endOf("week");

        this.dropOffAreaUuids = [];
        this.waypointUuids = [];
        this.equipmentIds = [];

        this.tradeIds = [];
        this.vendorIds = [];

        this.status = [];
        this.textSearch = "";
    }

    isUnfiltered(): boolean {
        const defaultFilter = new DeliveriesFilterInfo();

        return (
            this.archived === defaultFilter.archived &&
            this.projectIds.length === defaultFilter.projectIds.length &&
            (!this.startDate || this.startDate.isSame(defaultFilter.startDate)) &&
            (!this.endDate || this.endDate.isSame(defaultFilter.endDate)) &&
            this.dropOffAreaUuids.length === defaultFilter.dropOffAreaUuids.length &&
            this.waypointUuids.length === defaultFilter.waypointUuids.length &&
            this.equipmentIds.length === defaultFilter.equipmentIds.length &&
            this.tradeIds.length === defaultFilter.tradeIds.length &&
            this.vendorIds.length === defaultFilter.vendorIds.length &&
            this.status.length === defaultFilter.status.length &&
            this.textSearch === defaultFilter.textSearch
        );
    }

    toApiObject() {
        return {
            archived: this.archived,
            project_ids: this.projectIds,
            start_date: this.startDate?.toString(),
            end_date: this.endDate?.toString(),
            drop_off_area_ids: this.dropOffAreaUuids,
            waypoint_ids: this.waypointUuids,
            equipment_ids: this.equipmentIds,
            trade_ids: this.tradeIds,
            vendor_ids: this.vendorIds,
            statuses: this.status,
            text_search: this.textSearch,
        };
    }

    toQueryString() {
        const query: string[] = [];

        query.push("archived=" + this.archived);

        for (const projectId of this.projectIds) {
            query.push("project_ids=" + projectId);
        }

        if (this.startDate) {
            query.push("start_date=" + this.startDate.format("YYYY-MM-DD"));
        }

        if (this.endDate) {
            query.push("end_date=" + this.endDate.format("YYYY-MM-DD"));
        }

        for (const dropOffAreaId of this.dropOffAreaUuids) {
            query.push("drop_off_area_uuids=" + dropOffAreaId);
        }

        for (const waypointId of this.waypointUuids) {
            query.push("waypoint_uuids=" + waypointId);
        }

        for (const equipmentId of this.equipmentIds) {
            query.push("equipment_ids=" + equipmentId);
        }

        for (const tradeId of this.tradeIds) {
            query.push("trade_ids=" + tradeId);
        }

        for (const vendorId of this.vendorIds) {
            query.push("vendor_ids=" + vendorId);
        }

        for (const status of this.status) {
            query.push("statuses=" + status);
        }

        if (this.textSearch.length > 0) {
            query.push("text_search=" + this.textSearch);
        }

        return query.join("&");
    }
}

export default DeliveriesFilterInfo;
