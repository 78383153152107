import * as Sentry from "@sentry/react";
import { trackPromise } from "react-promise-tracker";
import { tempToColor } from "temp-color";

type endpoints = "realtime" | "forecast" | "current";
type units = "metric" | "imperial";

const weatherCodes = {
    "0": {
        description: "Unknown",
    },
    "1000": {
        description: "Clear, Sunny",
        nightIcon: "10001_clear_large.png",
        dayIcon: "10000_clear_large.png",
        defaultIcon: "10000_clear_large.png",
    },
    "1001": {
        description: "Cloudy",
        dayIcon: "10010_cloudy_large.png",
        defaultIcon: "10010_cloudy_large.png",
    },
    "1100": {
        description: "Mostly Clear",
        nightIcon: "11001_mostly_clear_large.png",
        dayIcon: "11000_mostly_clear_large.png",
        defaultIcon: "11000_mostly_clear_large.png",
    },
    "1101": {
        description: "Partly Cloudy",
        nightIcon: "11011_partly_cloudy_large.png",
        dayIcon: "11010_partly_cloudy_large.png",
        defaultIcon: "11010_partly_cloudy_large.png",
    },
    "1102": {
        description: "Mostly Cloudy",
        nightIcon: "11021_mostly_cloudy_large.png",
        dayIcon: "11020_mostly_cloudy_large.png",
        defaultIcon: "11020_mostly_cloudy_large.png",
    },
    "1103": {
        description: "Partly Cloudy and Mostly Clear",
        nightIcon: "11031_mostly_clear_large.png",
        dayIcon: "11030_mostly_clear_large.png",
        defaultIcon: "11030_mostly_clear_large.png",
    },
    "2000": {
        description: "Fog",
        dayIcon: "20000_fog_large.png",
        defaultIcon: "20000_fog_large.png",
    },
    "2100": {
        description: "Light Fog",
        dayIcon: "21000_fog_light_large.png",
        defaultIcon: "21000_fog_light_large.png",
    },
    "2101": {
        description: "Mostly Clear and Light Fog",
        nightIcon: "21011_fog_light_mostly_clear_large.png",
        dayIcon: "21010_fog_light_mostly_clear_large.png",
        defaultIcon: "21010_fog_light_mostly_clear_large.png",
    },
    "2102": {
        description: "Partly Cloudy and Light Fog",
        nightIcon: "21021_fog_light_partly_cloudy_large.png",
        dayIcon: "21020_fog_light_partly_cloudy_large.png",
        defaultIcon: "21020_fog_light_partly_cloudy_large.png",
    },
    "2103": {
        description: "Mostly Cloudy and Light Fog",
        nightIcon: "21031_fog_light_mostly_cloudy_large.png",
        dayIcon: "21030_fog_light_mostly_cloudy_large.png",
        defaultIcon: "21030_fog_light_mostly_cloudy_large.png",
    },
    "2106": {
        description: "Mostly Clear and Fog",
        nightIcon: "21061_fog_mostly_clear_large.png",
        dayIcon: "21060_fog_mostly_clear_large.png",
        defaultIcon: "21060_fog_mostly_clear_large.png",
    },
    "2107": {
        description: "Partly Cloudy and Fog",
        nightIcon: "21071_fog_partly_cloudy_large.png",
        dayIcon: "21070_fog_partly_cloudy_large.png",
        defaultIcon: "21070_fog_partly_cloudy_large.png",
    },
    "2108": {
        description: "Mostly Cloudy and Fog",
        nightIcon: "21081_fog_mostly_cloudy_large.png",
        dayIcon: "21080_fog_mostly_cloudy_large.png",
        defaultIcon: "21080_fog_mostly_cloudy_large.png",
    },
    "4000": {
        description: "Drizzle",
        dayIcon: "40000_drizzle_large.png",
        defaultIcon: "40000_drizzle_large.png",
    },
    "4001": {
        description: "Rain",
        dayIcon: "40010_rain_large.png",
        defaultIcon: "40010_rain_large.png",
    },
    "4200": {
        description: "Light Rain",
        dayIcon: "42000_rain_light_large.png",
        defaultIcon: "42000_rain_light_large.png",
    },
    "4201": {
        description: "Heavy Rain",
        dayIcon: "42010_rain_heavy_large.png",
        defaultIcon: "42010_rain_heavy_large.png",
    },
    "4202": {
        description: "Partly Cloudy and Heavy Rain",
        nightIcon: "42021_rain_heavy_partly_cloudy_large.png",
        dayIcon: "42020_rain_heavy_partly_cloudy_large.png",
        defaultIcon: "42020_rain_heavy_partly_cloudy_large.png",
    },
    "4203": {
        description: "Mostly Clear and Drizzle",
        nightIcon: "42031_drizzle_mostly_clear_large.png",
        dayIcon: "42030_drizzle_mostly_clear_large.png",
        defaultIcon: "42030_drizzle_mostly_clear_large.png",
    },
    "4204": {
        description: "Partly Cloudy and Drizzle",
        nightIcon: "42041_drizzle_partly_cloudy_large.png",
        dayIcon: "42040_drizzle_partly_cloudy_large.png",
        defaultIcon: "42040_drizzle_partly_cloudy_large.png",
    },
    "4205": {
        description: "Mostly Cloudy and Drizzle",
        nightIcon: "42051_drizzle_mostly_cloudy_large.png",
        dayIcon: "42050_drizzle_mostly_cloudy_large.png",
        defaultIcon: "42050_drizzle_mostly_cloudy_large.png",
    },
    "4208": {
        description: "Partly Cloudy and Rain",
        nightIcon: "42081_rain_partly_cloudy_large.png",
        dayIcon: "42080_rain_partly_cloudy_large.png",
        defaultIcon: "42080_rain_partly_cloudy_large.png",
    },
    "4209": {
        description: "Mostly Clear and Rain",
        nightIcon: "42091_rain_mostly_clear_large.png",
        dayIcon: "42090_rain_mostly_clear_large.png",
        defaultIcon: "42090_rain_mostly_clear_large.png",
    },
    "4210": {
        description: "Mostly Cloudy and Rain",
        nightIcon: "42101_rain_mostly_cloudy_large.png",
        dayIcon: "42100_rain_mostly_cloudy_large.png",
        defaultIcon: "42100_rain_mostly_cloudy_large.png",
    },
    "4211": {
        description: "Mostly Clear and Heavy Rain",
        nightIcon: "42111_rain_heavy_mostly_clear_large.png",
        dayIcon: "42110_rain_heavy_mostly_clear_large.png",
        defaultIcon: "42110_rain_heavy_mostly_clear_large.png",
    },
    "4212": {
        description: "Mostly Cloudy and Heavy Rain",
        nightIcon: "42121_rain_heavy_mostly_cloudy_large.png",
        dayIcon: "42120_rain_heavy_mostly_cloudy_large.png",
        defaultIcon: "42120_rain_heavy_mostly_cloudy_large.png",
    },
    "4213": {
        description: "Mostly Clear and Light Rain",
        nightIcon: "42131_rain_light_mostly_clear_large.png",
        dayIcon: "42130_rain_light_mostly_clear_large.png",
        defaultIcon: "42130_rain_light_mostly_clear_large.png",
    },
    "4214": {
        description: "Partly Cloudy and Light Rain",
        nightIcon: "42141_rain_light_partly_cloudy_large.png",
        dayIcon: "42140_rain_light_partly_cloudy_large.png",
        defaultIcon: "42140_rain_light_partly_cloudy_large.png",
    },
    "4215": {
        description: "Mostly Cloudy and Light Rain",
        nightIcon: "42151_rain_light_mostly_cloudy_large.png",
        dayIcon: "42150_rain_light_mostly_cloudy_large.png",
        defaultIcon: "42150_rain_light_mostly_cloudy_large.png",
    },
    "5000": {
        description: "Snow",
        dayIcon: "50000_snow_large.png",
        defaultIcon: "50000_snow_large.png",
    },
    "5001": {
        description: "Flurries",
        dayIcon: "50010_flurries_large.png",
        defaultIcon: "50010_flurries_large.png",
    },
    "5100": {
        description: "Light Snow",
        dayIcon: "51000_snow_light_large.png",
        defaultIcon: "51000_snow_light_large.png",
    },
    "5101": {
        description: "Heavy Snow",
        dayIcon: "51010_snow_heavy_large.png",
        defaultIcon: "51010_snow_heavy_large.png",
    },
    "5102": {
        description: "Mostly Clear and Light Snow",
        nightIcon: "51021_snow_light_mostly_clear_large.png",
        dayIcon: "51020_snow_light_mostly_clear_large.png",
        defaultIcon: "51020_snow_light_mostly_clear_large.png",
    },
    "5103": {
        description: "Partly Cloudy and Light Snow",
        nightIcon: "51031_snow_light_partly_cloudy_large.png",
        dayIcon: "51030_snow_light_partly_cloudy_large.png",
        defaultIcon: "51030_snow_light_partly_cloudy_large.png",
    },
    "5104": {
        description: "Mostly Cloudy and Light Snow",
        nightIcon: "51041_snow_light_mostly_cloudy_large.png",
        dayIcon: "51040_snow_light_mostly_cloudy_large.png",
        defaultIcon: "51040_snow_light_mostly_cloudy_large.png",
    },
    "5105": {
        description: "Mostly Clear and Snow",
        nightIcon: "51051_snow_mostly_clear_large.png",
        dayIcon: "51050_snow_mostly_clear_large.png",
        defaultIcon: "51050_snow_mostly_clear_large.png",
    },
    "5106": {
        description: "Partly Cloudy and Snow",
        nightIcon: "51061_snow_partly_cloudy_large.png",
        dayIcon: "51060_snow_partly_cloudy_large.png",
        defaultIcon: "51060_snow_partly_cloudy_large.png",
    },
    "5107": {
        description: "Mostly Cloudy and Snow",
        nightIcon: "51071_snow_mostly_cloudy_large.png",
        dayIcon: "51070_snow_mostly_cloudy_large.png",
        defaultIcon: "51070_snow_mostly_cloudy_large.png",
    },
    "5108": {
        description: "Rain and Snow",
        dayIcon: "51080_wintry_mix_large.png",
        defaultIcon: "51080_wintry_mix_large.png",
    },
    "5110": {
        description: "Drizzle and Snow",
        dayIcon: "51100_wintry_mix_large.png",
        defaultIcon: "51100_wintry_mix_large.png",
    },
    "5112": {
        description: "Snow and Ice Pellets",
        dayIcon: "51120_wintry_mix_large.png",
        defaultIcon: "51120_wintry_mix_large.png",
    },
    "5114": {
        description: "Snow and Freezing Rain",
        dayIcon: "51140_wintry_mix_large.png",
        defaultIcon: "51140_wintry_mix_large.png",
    },
    "5115": {
        description: "Mostly Clear and Flurries",
        nightIcon: "51151_flurries_mostly_clear_large.png",
        dayIcon: "51150_flurries_mostly_clear_large.png",
        defaultIcon: "51150_flurries_mostly_clear_large.png",
    },
    "5116": {
        description: "Partly Cloudy and Flurries",
        nightIcon: "51161_flurries_partly_cloudy_large.png",
        dayIcon: "51160_flurries_partly_cloudy_large.png",
        defaultIcon: "51160_flurries_partly_cloudy_large.png",
    },
    "5117": {
        description: "Mostly Cloudy and Flurries",
        nightIcon: "51171_flurries_mostly_cloudy_large.png",
        dayIcon: "51170_flurries_mostly_cloudy_large.png",
        defaultIcon: "51170_flurries_mostly_cloudy_large.png",
    },
    "5119": {
        description: "Mostly Clear and Heavy Snow",
        nightIcon: "51191_snow_heavy_mostly_clear_large.png",
        dayIcon: "51190_snow_heavy_mostly_clear_large.png",
        defaultIcon: "51190_snow_heavy_mostly_clear_large.png",
    },
    "5120": {
        description: "Partly Cloudy and Heavy Snow",
        nightIcon: "51201_snow_heavy_partly_cloudy_large.png",
        dayIcon: "51200_snow_heavy_partly_cloudy_large.png",
        defaultIcon: "51200_snow_heavy_partly_cloudy_large.png",
    },
    "5121": {
        description: "Mostly Cloudy and Heavy Snow",
        nightIcon: "51211_snow_heavy_mostly_cloudy_large.png",
        dayIcon: "51210_snow_heavy_mostly_cloudy_large.png",
        defaultIcon: "51210_snow_heavy_mostly_cloudy_large.png",
    },
    "5122": {
        description: "Drizzle and Light Snow",
        dayIcon: "51220_wintry_mix_large.png",
        defaultIcon: "51220_wintry_mix_large.png",
    },
    "6000": {
        description: "Freezing Drizzle",
        dayIcon: "60000_freezing_rain_drizzle_large.png",
        defaultIcon: "60000_freezing_rain_drizzle_large.png",
    },
    "6001": {
        description: "Freezing Rain",
        dayIcon: "60010_freezing_rain_large.png",
        defaultIcon: "60010_freezing_rain_large.png",
    },
    "6002": {
        description: "Partly Cloudy and Freezing drizzle",
        nightIcon: "60021_freezing_rain_drizzle_partly_cloudy_large.png",
        dayIcon: "60020_freezing_rain_drizzle_partly_cloudy_large.png",
        defaultIcon: "60020_freezing_rain_drizzle_partly_cloudy_large.png",
    },
    "6003": {
        description: "Mostly Clear and Freezing drizzle",
        nightIcon: "60031_freezing_rain_drizzle_mostly_clear_large.png",
        dayIcon: "60030_freezing_rain_drizzle_mostly_clear_large.png",
        defaultIcon: "60030_freezing_rain_drizzle_mostly_clear_large.png",
    },
    "6004": {
        description: "Mostly Cloudy and Freezing drizzle",
        nightIcon: "60041_freezing_rain_drizzle_mostly_cloudy_large.png",
        dayIcon: "60040_freezing_rain_drizzle_mostly_cloudy_large.png",
        defaultIcon: "60040_freezing_rain_drizzle_mostly_cloudy_large.png",
    },
    "6200": {
        description: "Light Freezing Rain",
        dayIcon: "62000_freezing_rain_light_large.png",
        defaultIcon: "62000_freezing_rain_light_large.png",
    },
    "6201": {
        description: "Heavy Freezing Rain",
        dayIcon: "62010_freezing_rain_heavy_large.png",
        defaultIcon: "62010_freezing_rain_heavy_large.png",
    },
    "6202": {
        description: "Partly Cloudy and Heavy Freezing Rain",
        nightIcon: "62021_freezing_rain_heavy_partly_cloudy_large.png",
        dayIcon: "62020_freezing_rain_heavy_partly_cloudy_large.png",
        defaultIcon: "62020_freezing_rain_heavy_partly_cloudy_large.png",
    },
    "6203": {
        description: "Partly Cloudy and Light Freezing Rain",
        nightIcon: "62031_freezing_rain_light_partly_cloudy_large.png",
        dayIcon: "62030_freezing_rain_light_partly_cloudy_large.png",
        defaultIcon: "62030_freezing_rain_light_partly_cloudy_large.png",
    },
    "6204": {
        description: "Drizzle and Freezing Drizzle",
        dayIcon: "62040_wintry_mix_large.png",
        defaultIcon: "62040_wintry_mix_large.png",
    },
    "6205": {
        description: "Mostly Clear and Light Freezing Rain",
        nightIcon: "62051_freezing_rain_light_mostly_clear_large.png",
        dayIcon: "62050_freezing_rain_light_mostly_clear_large.png",
        defaultIcon: "62050_freezing_rain_light_mostly_clear_large.png",
    },
    "6206": {
        description: "Light Rain and Freezing Drizzle",
        dayIcon: "62060_wintry_mix_large.png",
        defaultIcon: "62060_wintry_mix_large.png",
    },
    "6207": {
        description: "Mostly Clear and Heavy Freezing Rain",
        nightIcon: "62071_freezing_rain_heavy_mostly_clear_large.png",
        dayIcon: "62070_freezing_rain_heavy_mostly_clear_large.png",
        defaultIcon: "62070_freezing_rain_heavy_mostly_clear_large.png",
    },
    "6208": {
        description: "Mostly Cloudy and Heavy Freezing Rain",
        nightIcon: "62081_freezing_rain_heavy_mostly_cloudy_large.png",
        dayIcon: "62080_freezing_rain_heavy_mostly_cloudy_large.png",
        defaultIcon: "62080_freezing_rain_heavy_mostly_cloudy_large.png",
    },
    "6209": {
        description: "Mostly Cloudy and Light Freezing Rain",
        nightIcon: "62091_freezing_rain_light_mostly_cloudy_large.png",
        dayIcon: "62090_freezing_rain_light_mostly_cloudy_large.png",
        defaultIcon: "62090_freezing_rain_light_mostly_cloudy_large.png",
    },
    "6212": {
        description: "Drizzle and Freezing Rain",
        dayIcon: "62120_wintry_mix_large.png",
        defaultIcon: "62120_wintry_mix_large.png",
    },
    "6213": {
        description: "Mostly Clear and Freezing Rain",
        nightIcon: "62131_freezing_rain_mostly_clear_large.png",
        dayIcon: "62130_freezing_rain_mostly_clear_large.png",
        defaultIcon: "62130_freezing_rain_mostly_clear_large.png",
    },
    "6214": {
        description: "Partly Cloudy and Freezing Rain",
        nightIcon: "62141_freezing_rain_partly_cloudy_large.png",
        dayIcon: "62140_freezing_rain_partly_cloudy_large.png",
        defaultIcon: "62140_freezing_rain_partly_cloudy_large.png",
    },
    "6215": {
        description: "Mostly Cloudy and Freezing Rain",
        nightIcon: "62151_freezing_rain_mostly_cloudy_large.png",
        dayIcon: "62150_freezing_rain_mostly_cloudy_large.png",
        defaultIcon: "62150_freezing_rain_mostly_cloudy_large.png",
    },
    "6220": {
        description: "Light Rain and Freezing Rain",
        dayIcon: "62200_wintry_mix_large.png",
        defaultIcon: "62200_wintry_mix_large.png",
    },
    "6222": {
        description: "Rain and Freezing Rain",
        dayIcon: "62220_wintry_mix_large.png",
        defaultIcon: "62220_wintry_mix_large.png",
    },
    "7000": {
        description: "Ice Pellets",
        dayIcon: "70000_ice_pellets_large.png",
        defaultIcon: "70000_ice_pellets_large.png",
    },
    "7101": {
        description: "Heavy Ice Pellets",
        dayIcon: "71010_ice_pellets_heavy_large.png",
        defaultIcon: "71010_ice_pellets_heavy_large.png",
    },
    "7102": {
        description: "Light Ice Pellets",
        dayIcon: "71020_ice_pellets_light_large.png",
        defaultIcon: "71020_ice_pellets_light_large.png",
    },
    "7103": {
        description: "Freezing Rain and Heavy Ice Pellets",
        dayIcon: "71030_wintry_mix_large.png",
        defaultIcon: "71030_wintry_mix_large.png",
    },
    "7105": {
        description: "Drizzle and Ice Pellets",
        dayIcon: "71050_wintry_mix_large.png",
        defaultIcon: "71050_wintry_mix_large.png",
    },
    "7106": {
        description: "Freezing Rain and Ice Pellets",
        dayIcon: "71060_wintry_mix_large.png",
        defaultIcon: "71060_wintry_mix_large.png",
    },
    "7107": {
        description: "Partly Cloudy and Ice Pellets",
        nightIcon: "71071_ice_pellets_partly_cloudy_large.png",
        dayIcon: "71070_ice_pellets_partly_cloudy_large.png",
        defaultIcon: "71070_ice_pellets_partly_cloudy_large.png",
    },
    "7108": {
        description: "Mostly Clear and Ice Pellets",
        nightIcon: "71081_ice_pellets_mostly_clear_large.png",
        dayIcon: "71080_ice_pellets_mostly_clear_large.png",
        defaultIcon: "71080_ice_pellets_mostly_clear_large.png",
    },
    "7109": {
        description: "Mostly Cloudy and Ice Pellets",
        nightIcon: "71091_ice_pellets_mostly_cloudy_large.png",
        dayIcon: "71090_ice_pellets_mostly_cloudy_large.png",
        defaultIcon: "71090_ice_pellets_mostly_cloudy_large.png",
    },
    "7110": {
        description: "Mostly Clear and Light Ice Pellets",
        nightIcon: "71101_ice_pellets_light_mostly_clear_large.png",
        dayIcon: "71100_ice_pellets_light_mostly_clear_large.png",
        defaultIcon: "71100_ice_pellets_light_mostly_clear_large.png",
    },
    "7111": {
        description: "Partly Cloudy and Light Ice Pellets",
        nightIcon: "71111_ice_pellets_light_partly_cloudy_large.png",
        dayIcon: "71110_ice_pellets_light_partly_cloudy_large.png",
        defaultIcon: "71110_ice_pellets_light_partly_cloudy_large.png",
    },
    "7112": {
        description: "Mostly Cloudy and Light Ice Pellets",
        nightIcon: "71121_ice_pellets_light_mostly_cloudy_large.png",
        dayIcon: "71120_ice_pellets_light_mostly_cloudy_large.png",
        defaultIcon: "71120_ice_pellets_light_mostly_cloudy_large.png",
    },
    "7113": {
        description: "Mostly Clear and Heavy Ice Pellets",
        nightIcon: "71131_ice_pellets_heavy_mostly_clear_large.png",
        dayIcon: "71130_ice_pellets_heavy_mostly_clear_large.png",
        defaultIcon: "71130_ice_pellets_heavy_mostly_clear_large.png",
    },
    "7114": {
        description: "Partly Cloudy and Heavy Ice Pellets",
        nightIcon: "71141_ice_pellets_heavy_partly_cloudy_large.png",
        dayIcon: "71140_ice_pellets_heavy_partly_cloudy_large.png",
        defaultIcon: "71140_ice_pellets_heavy_partly_cloudy_large.png",
    },
    "7115": {
        description: "Light Rain and Ice Pellets",
        dayIcon: "71150_wintry_mix_large.png",
        defaultIcon: "71150_wintry_mix_large.png",
    },
    "7116": {
        description: "Mostly Cloudy and Heavy Ice Pellets",
        nightIcon: "71161_ice_pellets_heavy_mostly_cloudy_large.png",
        dayIcon: "71160_ice_pellets_heavy_mostly_cloudy_large.png",
        defaultIcon: "71160_ice_pellets_heavy_mostly_cloudy_large.png",
    },
    "7117": {
        description: "Rain and Ice Pellets",
        dayIcon: "71170_wintry_mix_large.png",
        defaultIcon: "71170_wintry_mix_large.png",
    },
    "8000": {
        description: "Thunderstorm",
        dayIcon: "80000_tstorm_large.png",
        defaultIcon: "80000_tstorm_large.png",
    },
    "8001": {
        description: "Mostly Clear and Thunderstorm",
        nightIcon: "80011_tstorm_mostly_clear_large.png",
        dayIcon: "80010_tstorm_mostly_clear_large.png",
        defaultIcon: "80010_tstorm_mostly_clear_large.png",
    },
    "8002": {
        description: "Mostly Cloudy and Thunderstorm",
        nightIcon: "80021_tstorm_mostly_cloudy_large.png",
        dayIcon: "80020_tstorm_mostly_cloudy_large.png",
        defaultIcon: "80020_tstorm_mostly_cloudy_large.png",
    },
    "8003": {
        description: "Partly Cloudy and Thunderstorm",
        nightIcon: "80031_tstorm_partly_cloudy_large.png",
        dayIcon: "80030_tstorm_partly_cloudy_large.png",
        defaultIcon: "80030_tstorm_partly_cloudy_large.png",
    },
    "10000": {
        description: "Clear, Sunny",
        defaultIcon: "10000_clear_large.png",
    },
    "10001": {
        description: "Clear",
        defaultIcon: "10001_clear_large.png",
    },
    "10010": {
        description: "Cloudy",
        defaultIcon: "10010_cloudy_large.png",
    },
    "10011": {
        description: "Cloudy",
    },
    "11000": {
        description: "Mostly Clear",
        defaultIcon: "11000_mostly_clear_large.png",
    },
    "11001": {
        description: "Mostly Clear",
        defaultIcon: "11001_mostly_clear_large.png",
    },
    "11010": {
        description: "Partly Cloudy",
        defaultIcon: "11010_partly_cloudy_large.png",
    },
    "11011": {
        description: "Partly Cloudy",
        defaultIcon: "11011_partly_cloudy_large.png",
    },
    "11020": {
        description: "Mostly Cloudy",
        defaultIcon: "11020_mostly_cloudy_large.png",
    },
    "11021": {
        description: "Mostly Cloudy",
        defaultIcon: "11021_mostly_cloudy_large.png",
    },
    "11030": {
        description: "Partly Cloudy and Mostly Clear",
        defaultIcon: "11030_mostly_clear_large.png",
    },
    "11031": {
        description: "Partly Cloudy and Mostly Clear",
        defaultIcon: "11031_mostly_clear_large.png",
    },
    "20000": {
        description: "Fog",
        defaultIcon: "20000_fog_large.png",
    },
    "20001": {
        description: "Fog",
    },
    "21000": {
        description: "Light Fog",
        defaultIcon: "21000_fog_light_large.png",
    },
    "21001": {
        description: "Light Fog",
    },
    "21010": {
        description: "Mostly Clear and Light Fog",
        defaultIcon: "21010_fog_light_mostly_clear_large.png",
    },
    "21011": {
        description: "Mostly Clear and Light Fog",
        defaultIcon: "21011_fog_light_mostly_clear_large.png",
    },
    "21020": {
        description: "Partly Cloudy and Light Fog",
        defaultIcon: "21020_fog_light_partly_cloudy_large.png",
    },
    "21021": {
        description: "Partly Cloudy and Light Fog",
        defaultIcon: "21021_fog_light_partly_cloudy_large.png",
    },
    "21030": {
        description: "Mostly Cloudy and Light Fog",
        defaultIcon: "21030_fog_light_mostly_cloudy_large.png",
    },
    "21031": {
        description: "Mostly Cloudy and Light Fog",
        defaultIcon: "21031_fog_light_mostly_cloudy_large.png",
    },
    "21060": {
        description: "Mostly Clear and Fog",
        defaultIcon: "21060_fog_mostly_clear_large.png",
    },
    "21061": {
        description: "Mostly Clear and Fog",
        defaultIcon: "21061_fog_mostly_clear_large.png",
    },
    "21070": {
        description: "Partly Cloudy and Fog",
        defaultIcon: "21070_fog_partly_cloudy_large.png",
    },
    "21071": {
        description: "Partly Cloudy and Fog",
        defaultIcon: "21071_fog_partly_cloudy_large.png",
    },
    "21080": {
        description: "Mostly Cloudy and Fog",
        defaultIcon: "21080_fog_mostly_cloudy_large.png",
    },
    "21081": {
        description: "Mostly Cloudy and Fog",
        defaultIcon: "21081_fog_mostly_cloudy_large.png",
    },
    "40000": {
        description: "Drizzle",
        defaultIcon: "40000_drizzle_large.png",
    },
    "40001": {
        description: "Drizzle",
    },
    "40010": {
        description: "Rain",
        defaultIcon: "40010_rain_large.png",
    },
    "40011": {
        description: "Rain",
    },
    "42000": {
        description: "Light Rain",
        defaultIcon: "42000_rain_light_large.png",
    },
    "42001": {
        description: "Light Rain",
    },
    "42010": {
        description: "Heavy Rain",
        defaultIcon: "42010_rain_heavy_large.png",
    },
    "42011": {
        description: "Heavy Rain",
    },
    "42020": {
        description: "Partly Cloudy and Heavy Rain",
        defaultIcon: "42020_rain_heavy_partly_cloudy_large.png",
    },
    "42021": {
        description: "Partly Cloudy and Heavy Rain",
        defaultIcon: "42021_rain_heavy_partly_cloudy_large.png",
    },
    "42030": {
        description: "Mostly Clear and Drizzle",
        defaultIcon: "42030_drizzle_mostly_clear_large.png",
    },
    "42031": {
        description: "Mostly Clear and Drizzle",
        defaultIcon: "42031_drizzle_mostly_clear_large.png",
    },
    "42040": {
        description: "Partly Cloudy and Drizzle",
        defaultIcon: "42040_drizzle_partly_cloudy_large.png",
    },
    "42041": {
        description: "Partly Cloudy and Drizzle",
        defaultIcon: "42041_drizzle_partly_cloudy_large.png",
    },
    "42050": {
        description: "Mostly Cloudy and Drizzle",
        defaultIcon: "42050_drizzle_mostly_cloudy_large.png",
    },
    "42051": {
        description: "Mostly Cloudy and Drizzle",
        defaultIcon: "42051_drizzle_mostly_cloudy_large.png",
    },
    "42080": {
        description: "Partly Cloudy and Rain",
        defaultIcon: "42080_rain_partly_cloudy_large.png",
    },
    "42081": {
        description: "Partly Cloudy and Rain",
        defaultIcon: "42081_rain_partly_cloudy_large.png",
    },
    "42090": {
        description: "Mostly Clear and Rain",
        defaultIcon: "42090_rain_mostly_clear_large.png",
    },
    "42091": {
        description: "Mostly Clear and Rain",
        defaultIcon: "42091_rain_mostly_clear_large.png",
    },
    "42100": {
        description: "Mostly Cloudy and Rain",
        defaultIcon: "42100_rain_mostly_cloudy_large.png",
    },
    "42101": {
        description: "Mostly Cloudy and Rain",
        defaultIcon: "42101_rain_mostly_cloudy_large.png",
    },
    "42110": {
        description: "Mostly Clear and Heavy Rain",
        defaultIcon: "42110_rain_heavy_mostly_clear_large.png",
    },
    "42111": {
        description: "Mostly Clear and Heavy Rain",
        defaultIcon: "42111_rain_heavy_mostly_clear_large.png",
    },
    "42120": {
        description: "Mostly Cloudy and Heavy Rain",
        defaultIcon: "42120_rain_heavy_mostly_cloudy_large.png",
    },
    "42121": {
        description: "Mostly Cloudy and Heavy Rain",
        defaultIcon: "42121_rain_heavy_mostly_cloudy_large.png",
    },
    "42130": {
        description: "Mostly Clear and Light Rain",
        defaultIcon: "42130_rain_light_mostly_clear_large.png",
    },
    "42131": {
        description: "Mostly Clear and Light Rain",
        defaultIcon: "42131_rain_light_mostly_clear_large.png",
    },
    "42140": {
        description: "Partly Cloudy and Light Rain",
        defaultIcon: "42140_rain_light_partly_cloudy_large.png",
    },
    "42141": {
        description: "Partly Cloudy and Light Rain",
        defaultIcon: "42141_rain_light_partly_cloudy_large.png",
    },
    "42150": {
        description: "Mostly Cloudy and Light Rain",
        defaultIcon: "42150_rain_light_mostly_cloudy_large.png",
    },
    "42151": {
        description: "Mostly Cloudy and Light Rain",
        defaultIcon: "42151_rain_light_mostly_cloudy_large.png",
    },
    "50000": {
        description: "Snow",
        defaultIcon: "50000_snow_large.png",
    },
    "50001": {
        description: "Snow",
    },
    "50010": {
        description: "Flurries",
        defaultIcon: "50010_flurries_large.png",
    },
    "50011": {
        description: "Flurries",
    },
    "51000": {
        description: "Light Snow",
        defaultIcon: "51000_snow_light_large.png",
    },
    "51001": {
        description: "Light Snow",
    },
    "51010": {
        description: "Heavy Snow",
        defaultIcon: "51010_snow_heavy_large.png",
    },
    "51011": {
        description: "Heavy Snow",
    },
    "51020": {
        description: "Mostly Clear and Light Snow",
        defaultIcon: "51020_snow_light_mostly_clear_large.png",
    },
    "51021": {
        description: "Mostly Clear and Light Snow",
        defaultIcon: "51021_snow_light_mostly_clear_large.png",
    },
    "51030": {
        description: "Partly Cloudy and Light Snow",
        defaultIcon: "51030_snow_light_partly_cloudy_large.png",
    },
    "51031": {
        description: "Partly Cloudy and Light Snow",
        defaultIcon: "51031_snow_light_partly_cloudy_large.png",
    },
    "51040": {
        description: "Mostly Cloudy and Light Snow",
        defaultIcon: "51040_snow_light_mostly_cloudy_large.png",
    },
    "51041": {
        description: "Mostly Cloudy and Light Snow",
        defaultIcon: "51041_snow_light_mostly_cloudy_large.png",
    },
    "51050": {
        description: "Mostly Clear and Snow",
        defaultIcon: "51050_snow_mostly_clear_large.png",
    },
    "51051": {
        description: "Mostly Clear and Snow",
        defaultIcon: "51051_snow_mostly_clear_large.png",
    },
    "51060": {
        description: "Partly Cloudy and Snow",
        defaultIcon: "51060_snow_partly_cloudy_large.png",
    },
    "51061": {
        description: "Partly Cloudy and Snow",
        defaultIcon: "51061_snow_partly_cloudy_large.png",
    },
    "51070": {
        description: "Mostly Cloudy and Snow",
        defaultIcon: "51070_snow_mostly_cloudy_large.png",
    },
    "51071": {
        description: "Mostly Cloudy and Snow",
        defaultIcon: "51071_snow_mostly_cloudy_large.png",
    },
    "51080": {
        description: "Rain and Snow",
        defaultIcon: "51080_wintry_mix_large.png",
    },
    "51081": {
        description: "Rain and Snow",
    },
    "51100": {
        description: "Drizzle and Snow",
        defaultIcon: "51100_wintry_mix_large.png",
    },
    "51101": {
        description: "Drizzle and Snow",
    },
    "51120": {
        description: "Snow and Ice Pellets",
        defaultIcon: "51120_wintry_mix_large.png",
    },
    "51121": {
        description: "Snow and Ice Pellets",
    },
    "51140": {
        description: "Snow and Freezing Rain",
        defaultIcon: "51140_wintry_mix_large.png",
    },
    "51141": {
        description: "Snow and Freezing Rain",
    },
    "51150": {
        description: "Mostly Clear and Flurries",
        defaultIcon: "51150_flurries_mostly_clear_large.png",
    },
    "51151": {
        description: "Mostly Clear and Flurries",
        defaultIcon: "51151_flurries_mostly_clear_large.png",
    },
    "51160": {
        description: "Partly Cloudy and Flurries",
        defaultIcon: "51160_flurries_partly_cloudy_large.png",
    },
    "51161": {
        description: "Partly Cloudy and Flurries",
        defaultIcon: "51161_flurries_partly_cloudy_large.png",
    },
    "51170": {
        description: "Mostly Cloudy and Flurries",
        defaultIcon: "51170_flurries_mostly_cloudy_large.png",
    },
    "51171": {
        description: "Mostly Cloudy and Flurries",
        defaultIcon: "51171_flurries_mostly_cloudy_large.png",
    },
    "51190": {
        description: "Mostly Clear and Heavy Snow",
        defaultIcon: "51190_snow_heavy_mostly_clear_large.png",
    },
    "51191": {
        description: "Mostly Clear and Heavy Snow",
        defaultIcon: "51191_snow_heavy_mostly_clear_large.png",
    },
    "51200": {
        description: "Partly Cloudy and Heavy Snow",
        defaultIcon: "51200_snow_heavy_partly_cloudy_large.png",
    },
    "51201": {
        description: "Partly Cloudy and Heavy Snow",
        defaultIcon: "51201_snow_heavy_partly_cloudy_large.png",
    },
    "51210": {
        description: "Mostly Cloudy and Heavy Snow",
        defaultIcon: "51210_snow_heavy_mostly_cloudy_large.png",
    },
    "51211": {
        description: "Mostly Cloudy and Heavy Snow",
        defaultIcon: "51211_snow_heavy_mostly_cloudy_large.png",
    },
    "51220": {
        description: "Drizzle and Light Snow",
        defaultIcon: "51220_wintry_mix_large.png",
    },
    "51221": {
        description: "Drizzle and Light Snow",
    },
    "60000": {
        description: "Freezing Drizzle",
        defaultIcon: "60000_freezing_rain_drizzle_large.png",
    },
    "60001": {
        description: "Freezing Drizzle",
    },
    "60010": {
        description: "Freezing Rain",
        defaultIcon: "60010_freezing_rain_large.png",
    },
    "60011": {
        description: "Freezing Rain",
    },
    "60020": {
        description: "Partly Cloudy and Freezing drizzle",
        defaultIcon: "60020_freezing_rain_drizzle_partly_cloudy_large.png",
    },
    "60021": {
        description: "Partly Cloudy and Freezing drizzle",
        defaultIcon: "60021_freezing_rain_drizzle_partly_cloudy_large.png",
    },
    "60030": {
        description: "Mostly Clear and Freezing drizzle",
        defaultIcon: "60030_freezing_rain_drizzle_mostly_clear_large.png",
    },
    "60031": {
        description: "Mostly Clear and Freezing drizzle",
        defaultIcon: "60031_freezing_rain_drizzle_mostly_clear_large.png",
    },
    "60040": {
        description: "Mostly Cloudy and Freezing drizzle",
        defaultIcon: "60040_freezing_rain_drizzle_mostly_cloudy_large.png",
    },
    "60041": {
        description: "Mostly Cloudy and Freezing drizzle",
        defaultIcon: "60041_freezing_rain_drizzle_mostly_cloudy_large.png",
    },
    "62000": {
        description: "Light Freezing Rain",
        defaultIcon: "62000_freezing_rain_light_large.png",
    },
    "62001": {
        description: "Light Freezing Rain",
    },
    "62010": {
        description: "Heavy Freezing Rain",
        defaultIcon: "62010_freezing_rain_heavy_large.png",
    },
    "62011": {
        description: "Heavy Freezing Rain",
    },
    "62020": {
        description: "Partly Cloudy and Heavy Freezing Rain",
        defaultIcon: "62020_freezing_rain_heavy_partly_cloudy_large.png",
    },
    "62021": {
        description: "Partly Cloudy and Heavy Freezing Rain",
        defaultIcon: "62021_freezing_rain_heavy_partly_cloudy_large.png",
    },
    "62030": {
        description: "Partly Cloudy and Light Freezing Rain",
        defaultIcon: "62030_freezing_rain_light_partly_cloudy_large.png",
    },
    "62031": {
        description: "Partly cloudy and Light Freezing Rain",
        defaultIcon: "62031_freezing_rain_light_partly_cloudy_large.png",
    },
    "62040": {
        description: "Drizzle and Freezing Drizzle",
        defaultIcon: "62040_wintry_mix_large.png",
    },
    "62041": {
        description: "Drizzle and Freezing Drizzle",
    },
    "62050": {
        description: "Mostly Clear and Light Freezing Rain",
        defaultIcon: "62050_freezing_rain_light_mostly_clear_large.png",
    },
    "62051": {
        description: "Mostly Clear and Light Freezing Rain",
        defaultIcon: "62051_freezing_rain_light_mostly_clear_large.png",
    },
    "62060": {
        description: "Light Rain and Freezing Drizzle",
        defaultIcon: "62060_wintry_mix_large.png",
    },
    "62061": {
        description: "Light Rain and Freezing Drizzle",
    },
    "62070": {
        description: "Mostly Clear and Heavy Freezing Rain",
        defaultIcon: "62070_freezing_rain_heavy_mostly_clear_large.png",
    },
    "62071": {
        description: "Mostly Clear and Heavy Freezing Rain",
        defaultIcon: "62071_freezing_rain_heavy_mostly_clear_large.png",
    },
    "62080": {
        description: "Mostly Cloudy and Heavy Freezing Rain",
        defaultIcon: "62080_freezing_rain_heavy_mostly_cloudy_large.png",
    },
    "62081": {
        description: "Mostly Cloudy and Heavy Freezing Rain",
        defaultIcon: "62081_freezing_rain_heavy_mostly_cloudy_large.png",
    },
    "62090": {
        description: "Mostly Cloudy and Light Freezing Rain",
        defaultIcon: "62090_freezing_rain_light_mostly_cloudy_large.png",
    },
    "62091": {
        description: "Mostly Cloudy and Light Freezing Rain",
        defaultIcon: "62091_freezing_rain_light_mostly_cloudy_large.png",
    },
    "62120": {
        description: "Drizzle and Freezing Rain",
        defaultIcon: "62120_wintry_mix_large.png",
    },
    "62121": {
        description: "Drizzle and Freezing Rain",
    },
    "62130": {
        description: "Mostly Clear and Freezing Rain",
        defaultIcon: "62130_freezing_rain_mostly_clear_large.png",
    },
    "62131": {
        description: "Mostly Clear and Freezing Rain",
        defaultIcon: "62131_freezing_rain_mostly_clear_large.png",
    },
    "62140": {
        description: "Partly Cloudy and Freezing Rain",
        defaultIcon: "62140_freezing_rain_partly_cloudy_large.png",
    },
    "62141": {
        description: "Partly Cloudy and Freezing Rain",
        defaultIcon: "62141_freezing_rain_partly_cloudy_large.png",
    },
    "62150": {
        description: "Mostly Cloudy and Freezing Rain",
        defaultIcon: "62150_freezing_rain_mostly_cloudy_large.png",
    },
    "62151": {
        description: "Mostly Cloudy and Freezing Rain",
        defaultIcon: "62151_freezing_rain_mostly_cloudy_large.png",
    },
    "62200": {
        description: "Light Rain and Freezing Rain",
        defaultIcon: "62200_wintry_mix_large.png",
    },
    "62201": {
        description: "Light Rain and Freezing Rain",
    },
    "62220": {
        description: "Rain and Freezing Rain",
        defaultIcon: "62220_wintry_mix_large.png",
    },
    "62221": {
        description: "Rain and Freezing Rain",
    },
    "70000": {
        description: "Ice Pellets",
        defaultIcon: "70000_ice_pellets_large.png",
    },
    "70001": {
        description: "Ice Pellets",
    },
    "71010": {
        description: "Heavy Ice Pellets",
        defaultIcon: "71010_ice_pellets_heavy_large.png",
    },
    "71011": {
        description: "Heavy Ice Pellets",
    },
    "71020": {
        description: "Light Ice Pellets",
        defaultIcon: "71020_ice_pellets_light_large.png",
    },
    "71021": {
        description: "Light Ice Pellets",
    },
    "71030": {
        description: "Freezing Rain and Heavy Ice Pellets",
        defaultIcon: "71030_wintry_mix_large.png",
    },
    "71031": {
        description: "Freezing Rain and Heavy Ice Pellets",
    },
    "71050": {
        description: "Drizzle and Ice Pellets",
        defaultIcon: "71050_wintry_mix_large.png",
    },
    "71051": {
        description: "Drizzle and Ice Pellets",
    },
    "71060": {
        description: "Freezing Rain and Ice Pellets",
        defaultIcon: "71060_wintry_mix_large.png",
    },
    "71061": {
        description: "Freezing Rain and Ice Pellets",
    },
    "71070": {
        description: "Partly Cloudy and Ice Pellets",
        defaultIcon: "71070_ice_pellets_partly_cloudy_large.png",
    },
    "71071": {
        description: "Partly Cloudy and Ice Pellets",
        defaultIcon: "71071_ice_pellets_partly_cloudy_large.png",
    },
    "71080": {
        description: "Mostly Clear and Ice Pellets",
        defaultIcon: "71080_ice_pellets_mostly_clear_large.png",
    },
    "71081": {
        description: "Mostly Clear and Ice Pellets",
        defaultIcon: "71081_ice_pellets_mostly_clear_large.png",
    },
    "71090": {
        description: "Mostly Cloudy and Ice Pellets",
        defaultIcon: "71090_ice_pellets_mostly_cloudy_large.png",
    },
    "71091": {
        description: "Mostly Cloudy and Ice Pellets",
        defaultIcon: "71091_ice_pellets_mostly_cloudy_large.png",
    },
    "71100": {
        description: "Mostly Clear and Light Ice Pellets",
        defaultIcon: "71100_ice_pellets_light_mostly_clear_large.png",
    },
    "71101": {
        description: "Mostly Clear and Light Ice Pellets",
        defaultIcon: "71101_ice_pellets_light_mostly_clear_large.png",
    },
    "71110": {
        description: "Partly Cloudy and Light Ice Pellets",
        defaultIcon: "71110_ice_pellets_light_partly_cloudy_large.png",
    },
    "71111": {
        description: "Partly Cloudy and Light Ice Pellets",
        defaultIcon: "71111_ice_pellets_light_partly_cloudy_large.png",
    },
    "71120": {
        description: "Mostly Cloudy and Light Ice Pellets",
        defaultIcon: "71120_ice_pellets_light_mostly_cloudy_large.png",
    },
    "71121": {
        description: "Mostly Cloudy and Light Ice Pellets",
        defaultIcon: "71121_ice_pellets_light_mostly_cloudy_large.png",
    },
    "71130": {
        description: "Mostly Clear and Heavy Ice Pellets",
        defaultIcon: "71130_ice_pellets_heavy_mostly_clear_large.png",
    },
    "71131": {
        description: "Mostly Clear and Heavy Ice Pellets",
        defaultIcon: "71131_ice_pellets_heavy_mostly_clear_large.png",
    },
    "71140": {
        description: "Partly Cloudy and Heavy Ice Pellets",
        defaultIcon: "71140_ice_pellets_heavy_partly_cloudy_large.png",
    },
    "71141": {
        description: "Partly Cloudy and Heavy Ice Pellets",
        defaultIcon: "71141_ice_pellets_heavy_partly_cloudy_large.png",
    },
    "71150": {
        description: "Light Rain and Ice Pellets",
        defaultIcon: "71150_wintry_mix_large.png",
    },
    "71151": {
        description: "Light Rain and Ice Pellets",
    },
    "71160": {
        description: "Mostly Cloudy and Heavy Ice Pellets",
        defaultIcon: "71160_ice_pellets_heavy_mostly_cloudy_large.png",
    },
    "71161": {
        description: "Mostly Cloudy and Heavy Ice Pellets",
        defaultIcon: "71161_ice_pellets_heavy_mostly_cloudy_large.png",
    },
    "71170": {
        description: "Rain and Ice Pellets",
        defaultIcon: "71170_wintry_mix_large.png",
    },
    "71171": {
        description: "Rain and Ice Pellets",
    },
    "80000": {
        description: "Thunderstorm",
        defaultIcon: "80000_tstorm_large.png",
    },
    "80001": {
        description: "Thunderstorm",
    },
    "80010": {
        description: "Mostly Clear and Thunderstorm",
        defaultIcon: "80010_tstorm_mostly_clear_large.png",
    },
    "80011": {
        description: "Mostly Clear and Thunderstorm",
        defaultIcon: "80011_tstorm_mostly_clear_large.png",
    },
    "80020": {
        description: "Mostly Cloudy and Thunderstorm",
        defaultIcon: "80020_tstorm_mostly_cloudy_large.png",
    },
    "80021": {
        description: "Mostly Cloudy and Thunderstorm",
        defaultIcon: "80021_tstorm_mostly_cloudy_large.png",
    },
    "80030": {
        description: "Partly Cloudy and Thunderstorm",
        defaultIcon: "80030_tstorm_partly_cloudy_large.png",
    },
    "80031": {
        description: "Partly Cloudy and Thunderstorm",
        defaultIcon: "80031_tstorm_partly_cloudy_large.png",
    },
};

const Weather = {
    getWeatherMapTiles(zoom: number, x: number, y: number, field: string, time: string = "now") {
        const uri = `https://api.tomorrow.io/v4/map/tile/${zoom}/${x}/${y}/${field}/${time}.png`;
        fetch("/tomorrow_proxy", {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uri: uri,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                return response;
            })
            .catch((error) => Sentry.captureException(error));
    },
    getWeatherInformation(location: string, endpoint: endpoints, units: units = "imperial") {
        const uri = `https://api.tomorrow.io/v4/weather/${endpoint}?location=${location}&units=${units}`;
        return trackPromise(
            fetch("/tomorrow_proxy", {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uri: uri,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    return null;
                })
        );
    },
    getWeatherDescription(code: number) {
        const weatherCode = weatherCodes[code];

        return weatherCode.description;
    },
    getWeatherIcon(code: number, timeOfDay: number | null = null, xl: boolean = false) {
        const weatherCode = weatherCodes[code];
        let icon: string | null = null;

        if (weatherCode.defaultIcon) {
            icon = weatherCode.defaultIcon;
        }
        if (timeOfDay == 1 && weatherCode[code].dayIcon) {
            icon = weatherCode.dayIcon;
        }
        if (timeOfDay == 2 && weatherCode[code].nightIcon) {
            icon = weatherCode.nightIcon;
        }
        if (icon) {
            if (xl) {
                icon = icon.replace(".png", "@2x.png");
                return icon;
            }
            return icon;
        } else {
            return null;
        }
    },
    getTemperatureColor(temperature: number) {
        const min = 0; //lowest possible value (scale begins there)
        const max = 110; //highest possible value (scale ends there)
        const color = tempToColor(temperature, min, max);
        return `rgb(${color.r},${color.g},${color.b})`;
    },
    degreesToCompass(deg) {
        const directions = [
            "N",
            "NNE",
            "NE",
            "ENE",
            "E",
            "ESE",
            "SE",
            "SSE",
            "S",
            "SSW",
            "SW",
            "WSW",
            "W",
            "WNW",
            "NW",
            "NNW",
        ];

        const val = Math.floor(deg / 22.5 + 0.5);

        return directions[val % 16];
    },
    getLeftMarginPercentage(rangeHigh: number, rangeLow: number, minTemp: number) {
        const diff = (minTemp - rangeLow) / (rangeHigh - rangeLow);

        return (diff * 100).toFixed() + "%";
    },
    getRightMarginPercentage(rangeHigh, rangeLow, maxTemp) {
        const diff = (rangeHigh - maxTemp) / (rangeHigh - rangeLow);

        return (diff * 100).toFixed() + "%";
    },
};

export default Weather;
