import { useEffect, useState } from "react";
import PlotAPI from "../plotAPI";
import TeamInfo from "../teamInfo";

interface useTeamsProps {
    projects: any[];
    projectInfo: (projectId: string | number) => any | null;
}

const useTeams = (props: useTeamsProps) => {
    const [teamsByProject, setProjectsTeams] = useState<{ unique_token: string; teams: TeamInfo[] }[]>([]);

    useEffect(() => {
        refreshTeamsByProject();
    }, [props.projects]);

    const refreshTeamsByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getPublicProjectMembers(
                    project.unique_token,
                    project.claimed_by_team_subdomain || "public"
                ).then((response) => {
                    return {
                        unique_token: project.unique_token,
                        teams: response,
                    };
                })
            )
        ).then((teams) => {
            setProjectsTeams(teams);
        });
    };

    const teamsForProject = (projectId: string | number): TeamInfo[] => {
        const projectToken = props.projectInfo(projectId)?.unique_token;

        return teamsByProject.find((projectTeam) => projectTeam.unique_token === projectToken)?.teams || [];
    };

    return {
        teamsByProject,
        teamsForProject,
        refreshTeamsByProject,
    };
};

export default useTeams;
