import { useState } from "react";

const useUIHook = () => {
    const [activeSidebarKey, setActiveSidebarKey] = useState("portal");
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const [showNotificationsDrawer, setShowNotificationsDrawer] = useState(false);
    const [addTeamToProjectModal, setAddTeamToProjectModal] = useState<"member" | "vendor" | null>(null);
    return {
        activeSidebarKey,
        setActiveSidebarKey,
        collapseSidebar,
        setCollapseSidebar,
        showNotificationsDrawer,
        setShowNotificationsDrawer,
        addTeamToProjectModal,
        setAddTeamToProjectModal,
    };
};

export default useUIHook;
