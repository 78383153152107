import { useEffect, useState } from "react";
import PlotAPI from "../plotAPI";
import TeamInfo from "../teamInfo";

interface useVendorsProps {
    projects: any[];
    projectInfo: (projectId: string | number) => any | null;
}

const useVendors = (props: useVendorsProps) => {
    const [vendorsByProject, setvendorsByProject] = useState<{ unique_token: string; vendorTeams: TeamInfo[] }[]>([]);

    useEffect(() => {
        refreshVendorsByProject();
    }, [props.projects]);

    const refreshVendorsByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getvendorsForProject(project.unique_token, project.claimed_by_team_subdomain || "public").then(
                    (response) => {
                        return {
                            unique_token: project.unique_token,
                            vendorTeams: response.map((team) => new TeamInfo(team)),
                        };
                    }
                )
            )
        ).then((vendors) => {
            setvendorsByProject(vendors);
        });
    };

    const vendorsForProject = (projectId: string | number): TeamInfo[] => {
        const projectToken = props.projectInfo(projectId)?.unique_token;

        return vendorsByProject.find((projectVendor) => projectVendor.unique_token === projectToken)?.vendorTeams || [];
    };

    return {
        vendorsByProject,
        vendorsForProject,
        refreshVendorsByProject,
    };
};

export default useVendors;
