import useDeliveries from "./useDeliveries";
import useEquipment from "./useEquipment";
import useMapAreas from "./useMapAreas";
import useNewEquipment from "./useNewEquipment";
import useTeams from "./useTeams";
import useVendors from "./useVendors";

interface useProjectInfoProps {
    projects: any[];
    overrideTimezone: string | null;
}

const useProjectInfo = (props: useProjectInfoProps) => {
    const projectInfo = (projectId: string | number | null): any | null => {
        if (!projectId) {
            return null;
        }

        return (
            props.projects.find(
                (projectInfo) => projectInfo.id === projectId || projectInfo.unique_token == projectId
            ) || null
        );
    };

    const { mapAreasByProject, mapAreasForProject, refreshMapAreasByProject } = useMapAreas({
        projects: props.projects,
        projectInfo: projectInfo,
    });

    const { equipmentByProject, equipmentForProject, refreshEquipmentByProject } = useEquipment({
        projects: props.projects,
        projectInfo: projectInfo,
    });

    const { teamsByProject, teamsForProject, refreshTeamsByProject } = useTeams({
        projects: props.projects,
        projectInfo: projectInfo,
    });

    const { vendorsByProject, vendorsForProject, refreshVendorsByProject } = useVendors({
        projects: props.projects,
        projectInfo: projectInfo,
    });

    const {
        deliveriesByProject,
        refreshDeliveriesByProject,
        deliveriesForProject,
        deliveriesFilterInfo,
        setDeliveriesFilterInfo,
    } = useDeliveries({
        projects: props.projects,
        projectInfo: projectInfo,
        overrideTimezone: props.overrideTimezone,
    });

    const { newEquipmentByProject, newEquipmentForProject, refreshNewEquipmentByProject } = useNewEquipment({
        projects: props.projects,
        projectInfo: projectInfo,
        overrideTimezone: props.overrideTimezone,
    });

    return {
        projectInfo,
        mapAreasByProject,
        mapAreasForProject,
        refreshMapAreasByProject,
        equipmentByProject,
        equipmentForProject,
        refreshEquipmentByProject,
        teamsByProject,
        teamsForProject,
        refreshTeamsByProject,
        vendorsByProject,
        vendorsForProject,
        refreshVendorsByProject,
        deliveriesByProject,
        deliveriesForProject,
        refreshDeliveriesByProject,
        deliveriesFilterInfo,
        setDeliveriesFilterInfo,
        newEquipmentByProject,
        newEquipmentForProject,
        refreshNewEquipmentByProject,
    };
};

export default useProjectInfo;
