import { useEffect, useState } from "react";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        handleWindowSizeChange();
    }, []);

    const handleWindowSizeChange = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });

        if (window.innerWidth < 992) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    return { windowSize, isMobile };
};

export default useWindowSize;
