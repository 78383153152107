import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardContent, CardHeader, Grid, ThemeProvider, createTheme } from "@mui/material";
import { notification } from "antd";
import React from "react";
import { globalAppConfig } from "../../config";
import PlotAPI from "../../plotAPI";
import Modal from "../UI/Modal";

export default function PromptOnLink() {
    const [magicTokenUserFullName, setMagicTokenUserFullName] = React.useState<any | null>(null);
    const [, setMagicTokenUserId] = React.useState<any | null>(null);
    const [magicTokenUserPictureUrl, setMagicTokenUserPictureUrl] = React.useState<any | null>(null);
    const [lastLoggedInUserFullName, setLastLoggedInUserFullName] = React.useState<any | null>(null);
    const [, setLastLoggedInUserId] = React.useState<any | null>(null);
    const [lastLoggedInUserPictureUrl, setLastLoggedInUserPictureUrl] = React.useState<any | null>(null);
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("magic_token") && urlParams.get("token")) {
            PlotAPI.getMagicTokenInfo(urlParams.get("magic_token"), urlParams.get("token")).then((data) => {
                if (data) {
                    if (data.success && data.success === true) {
                        preflightPassed();
                    } else {
                        setMagicTokenUserFullName(data.magic_token_user.fullname);
                        setMagicTokenUserId(data.magic_token_user.user_id);
                        setMagicTokenUserPictureUrl(data.magic_token_user.picture_url);
                        setLastLoggedInUserFullName(data.last_logged_in_user.fullname);
                        setLastLoggedInUserId(data.last_logged_in_user.user_id);
                        setLastLoggedInUserPictureUrl(data.last_logged_in_user.picture_url);
                        setVisible(true);
                    }
                }
            });
        }
    }, []);

    const theme = createTheme();
    theme.typography.h5 = {
        fontSize: "small",
        fontFamily: "Oswald, sans-serif",
        textTransform: "uppercase",
    };

    const postLoginRedirect = async () => {
        window.location.href = "/auth/redirect";
    };

    function preflightPassed() {
        const urlParams = new URLSearchParams(window.location.search);
        const magicToken = urlParams.get("magic_token");
        const token = urlParams.get("token");
        PlotAPI.getMagicTokenInfo(magicToken, token, true)
            .then((response) => {
                if (response.error) {
                    notification.error({
                        message: "Failed to Log In",
                        description: "The token was invalid or expired (1872).",
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                    return;
                }
                if (response) {
                    if (response.return_url) {
                        window.location.href = response.return_url;
                    } else {
                        postLoginRedirect();
                    }
                } else {
                    notification.error({
                        message: "Failed to Log In",
                        description: "The token was invalid or expired (2872).",
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                }
            })
            .catch(() => {
                notification.error({
                    message: "Failed to Log In",
                    description: "The token was invalid or expired (3872).",
                    duration: globalAppConfig.NOTIFICATION_DURATION,
                });
            });
    }

    function closePrompt() {
        setVisible(false);
    }

    // TODO: Refactor this out.
    function stringToColor(string: string) {
        let hash = 0;
        let i = 1;
        if (string) {
            for (i = 0; i < string.length; i += 1) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
            }
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    }

    // TODO: Refactor this out.
    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: getInitials(name),
        };
    }

    // TODO: Refactor this out.
    function getInitials(nameString) {
        if (nameString) {
            const fullName = nameString.split(" ");
            let initials = "";
            if (fullName.length > 1) {
                initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
            } else {
                initials = fullName.shift().charAt(0);
            }
            return initials.toUpperCase();
        }

        return "";
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Modal destroyOnClose={true} title={"Switch User?"} open={visible} onCancel={closePrompt} footer={null}>
                    <Card style={{ marginTop: "3px" }}>
                        <CardHeader
                            style={{ borderBottom: "1px #ccc solid" }}
                            title={
                                <>
                                    <h1>Current or Last Logged in User</h1>
                                </>
                            }
                        ></CardHeader>

                        <CardContent>
                            <>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Avatar
                                            src={lastLoggedInUserPictureUrl}
                                            {...stringAvatar(lastLoggedInUserFullName)}
                                        />
                                    </Grid>
                                    <Grid item style={{ paddingTop: "24px" }}>
                                        {lastLoggedInUserFullName}
                                    </Grid>
                                </Grid>
                            </>
                        </CardContent>
                    </Card>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <FontAwesomeIcon color="#ccc" size="3x" icon={faArrowDown as IconProp}></FontAwesomeIcon>
                    </div>
                    <Card style={{ marginTop: "3px" }}>
                        <CardHeader
                            style={{ borderBottom: "1px #ccc solid" }}
                            title={
                                <>
                                    <h1>Log In As</h1>
                                </>
                            }
                        ></CardHeader>
                        <CardContent>
                            <>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Avatar
                                            src={magicTokenUserPictureUrl}
                                            {...stringAvatar(magicTokenUserFullName)}
                                        />
                                    </Grid>
                                    <Grid item style={{ paddingTop: "24px" }}>
                                        {magicTokenUserFullName}
                                    </Grid>
                                </Grid>
                            </>
                        </CardContent>
                    </Card>
                    <div className="mt-3 flex">
                        <button type="button" className="plot-button secondary-button flex-1" onClick={closePrompt}>
                            Cancel
                        </button>
                        <button type="button" className="plot-button primary-button flex-1" onClick={preflightPassed}>
                            Switch User
                        </button>
                    </div>
                </Modal>
            </ThemeProvider>
        </>
    );
}
