import { useEffect, useState } from "react";
import Helper from "../Helper";
import PlotAPI from "../plotAPI";
import TeamInfo from "../teamInfo";

interface useCurrentTeamProps {
    usersTeams: TeamInfo[];
}

const useCurrentTeam = (props: useCurrentTeamProps) => {
    const [currentTeam, _setCurrentTeam] = useState<null | TeamInfo>(null); // Current Team that the user is acting as
    const [currentTeamsProjects, setCurrentTeamsProjects] = useState<{
        member_of: any[];
        not_member_of: any[];
        projects: any[];
        recent_projects: any[];
        vendor_for: any[];
    }>({ member_of: [], not_member_of: [], projects: [], recent_projects: [], vendor_for: [] }); // Projects that the current Team belongs to (claimed and joined)

    // Users Teams List Changed?
    useEffect(() => {
        if (props.usersTeams) {
            if (Helper.getCookie("team") === "" && props.usersTeams.length > 0) {
                setCurrentTeam(props.usersTeams[0].id);
            } else {
                const team_id = Helper.getCookie("team");
                setCurrentTeam(parseInt(team_id));
            }
        }
    }, [props.usersTeams]);

    useEffect(() => {
        if (currentTeam) {
            reloadCurrentTeamsProjects();
        }
    }, [currentTeam]);

    const reloadCurrentTeamsProjects = (): Promise<void> => {
        if (!currentTeam) {
            return Promise.resolve();
        }

        return PlotAPI.getProjectsOnTeam(currentTeam.subdomain).then((response) => {
            setCurrentTeamsProjects(
                response || { member_of: [], not_member_of: [], projects: [], recent_projects: [], vendor_for: [] }
            );
        });
    };

    /**
     * Sets the current team by id by updating the cookie
     * @param team_id ID to set as the current team
     * @returns none
     */
    const setCurrentTeam = (team_id): void => {
        if (team_id && props.usersTeams) {
            const current_team = props.usersTeams.find((team) => team.id === team_id);

            if (current_team) {
                Helper.setCookie("team", current_team.id, 365);
                _setCurrentTeam(current_team);
                return;
            } else if (props.usersTeams.length > 0) {
                Helper.setCookie("team", props.usersTeams[0].id, 365);
                _setCurrentTeam(props.usersTeams[0]);
                return;
            }
        }

        _setCurrentTeam(null);
    };

    const reloadCurrentTeam = (): void => {
        if (!currentTeam) {
            return;
        }

        PlotAPI.getTeam(currentTeam.subdomain).then(() => {
            _setCurrentTeam(currentTeam);
        });
    };

    return { currentTeam, currentTeamsProjects, setCurrentTeam, reloadCurrentTeam, reloadCurrentTeamsProjects };
};

export default useCurrentTeam;
