import { useEffect, useState } from "react";
import { Equipment } from "../components/Equipment/equipmentTypes";
import PlotAPI from "../plotAPI";

interface useEquipmentProps {
    projects: any[];
    projectInfo: (projectId: string | number) => any | null;
}

const useEquipment = (props: useEquipmentProps) => {
    const [equipmentByProject, setProjectsEquipment] = useState<{ unique_token: string; equipment: Equipment[] }[]>([]);

    useEffect(() => {
        refreshEquipmentByProject();
    }, [props.projects]);

    const refreshEquipmentByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getEquipmentsForProject(
                    project.unique_token,
                    project.claimed_by_team_subdomain || "projects"
                ).then((equipment) => {
                    return {
                        unique_token: project.unique_token,
                        equipment: equipment,
                    };
                })
            )
        ).then((equipment) => {
            setProjectsEquipment(equipment);
        });
    };

    const equipmentForProject = (projectId: string | number): Equipment[] => {
        const projectToken = props.projectInfo(projectId)?.unique_token;

        return (
            equipmentByProject.find((equipmentForProject) => equipmentForProject.unique_token === projectToken)
                ?.equipment || []
        );
    };

    return {
        equipmentByProject,
        equipmentForProject,
        refreshEquipmentByProject,
    };
};

export default useEquipment;
