import { useEffect, useState } from "react";
import PlotAPI from "../plotAPI";

const useCurrentProjectInfo = () => {
    const [currentProjectToken, _setCurrentProjectToken] = useState<null | string>(null); // Token of the current Project being viewed
    const [currentProjectInfo, _setCurrentProjectInfo] = useState<null | any>(null); // Project Info of the current Project being viewed

    // Current Project Token Changed?
    useEffect(() => {
        if (currentProjectToken) {
            PlotAPI.getProjectInfo(currentProjectToken).then((response) => {
                _setCurrentProjectInfo(response);
            });
        }
    }, [currentProjectToken]);

    /**
     * Reload the current project without reloading the page
     * @returns Boolean indicatinng success of reload
     */
    const reloadCurrentProject = (): Promise<boolean> => {
        if (currentProjectToken) {
            return PlotAPI.getProjectInfo(currentProjectToken).then((response) => {
                _setCurrentProjectInfo(response);

                return true;
            });
        }

        return Promise.resolve(true);
    };

    /**
     * Set the current project token, this will set off the chain of reloading the current project info and checking access level for permissions
     * @param token Token of the new project info to get
     */
    const setCurrentProjectToken = (token: string): void => {
        _setCurrentProjectToken(token);
    };

    return { currentProjectToken, setCurrentProjectToken, currentProjectInfo, reloadCurrentProject };
};

export default useCurrentProjectInfo;
