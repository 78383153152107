import { Cable, Channel, createConsumer } from "@anycable/web";
import { notification } from "antd";
import { useEffect, useState } from "react";

interface useAnyCableProps {
    projectInfo: any | null;
    currentTeamsProjects: any | null;
    setNewBroadcastMessage: (value: boolean) => void;
}

const useAnyCable = (props: useAnyCableProps) => {
    const [cableApp, setCableApp] = useState<Cable | null>(null);
    const [dashboardNotificationsChannel, setDashboardNotificationsChannel] = useState<Channel | null>(null);
    const [mapChannel, setMapChannel] = useState<Channel | null>(null);
    const [deliveriesChannel, setDeliveriesChannel] = useState<Channel[]>([]);
    const [broadcastsChannel, setBroadcastsChannel] = useState<Channel | null>(null);

    useEffect(() => {
        setCableApp(createConsumer());

        return () => {
            cableApp?.disconnect();

            dashboardNotificationsChannel?.disconnect();
            mapChannel?.disconnect();
            deliveriesChannel?.map((item) => item?.disconnect());
            broadcastsChannel?.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!cableApp || !props.projectInfo) return;

        // @ts-expect-error: This is a compatibility function that does not have it's api exposed
        const newMapChannel = cableApp.subscriptions.create({
            channel: "MapChannel",
            project_unique_token: props.projectInfo.unique_token,
        });
        setMapChannel(newMapChannel);

        // @ts-expect-error: This is a compatibility function that does not have it's api exposed
        const newDashboardNotificationsChannel = cableApp.subscriptions.create({
            channel: "DashboardNotificationsChannel",
        });
        setDashboardNotificationsChannel(newDashboardNotificationsChannel);

        // @ts-expect-error: This is a compatibility function that does not have it's api exposed
        const newBroadcastsChannel = cableApp.subscriptions.create(
            {
                channel: "BroadcastsChannel",
                project_unique_token: props.projectInfo.unique_token,
            },
            {
                received: (data) => {
                    props.setNewBroadcastMessage(true);
                    notification.warning({
                        message: `New Jobsite Alert from ${data.user.fullname}`,
                        description: data.message.body,
                        duration: 10,
                        placement: "topLeft",
                    });
                },
            }
        );
        setBroadcastsChannel(newBroadcastsChannel);
    }, [cableApp, props.projectInfo]);

    useEffect(() => {
        if (!cableApp || !props.currentTeamsProjects || !props.projectInfo) return;

        const channel: (Channel & {
            received: (data: any) => void;
        })[] = [];

        if (props.projectInfo) {
            // @ts-expect-error: This is a compatibility function that does not have it's api exposed
            channel[props.projectInfo.unique_token] = cableApp.subscriptions.create(
                {
                    channel: "DeliveriesChannel",
                    project_unique_token: props.projectInfo.unique_token,
                },
                {
                    received: (data) => {
                        if (data.method == "update" && data.type == "delivery") {
                            // TODO: LATER ME RELOAD THE DELIVERIES, TELL DASHBOARD TO DO IT
                        }
                    },
                }
            );
        } else {
            const recent_project_ids = props.currentTeamsProjects?.recent_projects.map((project) => project.project_id);
            props.currentTeamsProjects?.member_of
                .filter((project) => recent_project_ids.includes(project.id))
                .forEach((project) => {
                    // @ts-expect-error: This is a compatibility function that does not have it's api exposed
                    channel[project.unique_token] = cableApp.subscriptions.create(
                        {
                            channel: "DeliveriesChannel",
                            project_unique_token: project.unique_token,
                        },
                        {
                            received: (data) => {
                                if (data.method == "update" && data.type == "delivery") {
                                    // TODO: LATER ME RELOAD THE DELIVERIES, TELL DASHBOARD TO DO IT
                                }
                            },
                        }
                    );
                });
        }

        setDeliveriesChannel(channel);
    }, [cableApp, props.currentTeamsProjects, props.projectInfo]);

    return {
        cableApp,
        dashboardNotificationsChannel,
        mapChannel,
        deliveriesChannel,
    };
};

export default useAnyCable;
