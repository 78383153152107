import { useEffect, useState } from "react";
import PlotAPI from "../plotAPI";

interface useMapAreasProps {
    projects: any[];
    projectInfo: (projectId: string | number) => any | null;
}

const useMapAreas = (props: useMapAreasProps) => {
    const [mapAreasByProject, setProjectsMapAreas] = useState<{ unique_token: string; areas: any[] }[]>([]);

    useEffect(() => {
        refreshMapAreasByProject();
    }, [props.projects]);

    const refreshMapAreasByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getProjectMapLayers(project.unique_token, project.claimed_by_team_subdomain || "projects").then(
                    (layers) => {
                        return {
                            unique_token: project.unique_token,
                            areas: layers?.map((layer) => layer.features)?.flat() || [],
                        };
                    }
                )
            )
        ).then((layers) => {
            setProjectsMapAreas(layers);
        });
    };

    const mapAreasForProject = (projectId: string | number): any[] => {
        const projectToken = props.projectInfo(projectId)?.unique_token;

        return mapAreasByProject.find((project) => project.unique_token === projectToken)?.areas || [];
    };

    return {
        mapAreasByProject,
        mapAreasForProject,
        refreshMapAreasByProject,
    };
};

export default useMapAreas;
