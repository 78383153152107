import { notification } from "antd";
import { useEffect, useState } from "react";
import { globalAppConfig } from "../config";
import { Mixpanel } from "../Mixpanel";
import PageContentType from "../PageContentType";
import PlotAPI from "../plotAPI";
import TeamInfo from "../teamInfo";
import UserInfo from "../userInfo";

interface useUserInfoProps {
    pageContentType: PageContentType | null;
    currentProjectInfo: any | null;
}

const useUserInfo = (props: useUserInfoProps) => {
    const [userInfo, _setUserInfo] = useState<null | UserInfo>(null); // User info for the current user
    const [gottenUserInfo, setGottenUserInfo] = useState(false);
    const [userPic, _setUserPic] = useState<null | string>(null); // Profile pic for the current user
    const [loggedIn, setLoggedIn] = useState(false); // Whether the user is logged in

    const [usersTeams, setUsersTeams] = useState<TeamInfo[]>([]); // Teams that a user belongs to

    useEffect(() => {
        setGottenUserInfo(false);
        PlotAPI.getCurrentUserInfo().then((response) => {
            if (response) {
                _setUserInfo(new UserInfo(response));
            }

            setLoggedIn(response ? true : false);
            setGottenUserInfo(true);

            if (response) {
                let pageName = "UNKNOWN";
                if (props.pageContentType == PageContentType.PROJECT) {
                    pageName = "PROJECT";
                }
                if (props.pageContentType == PageContentType.TEAM) {
                    pageName = "TEAM";
                }
                if (props.pageContentType == PageContentType.UNCLAIMED_TEAM) {
                    pageName = "UNCLAIMED TEAM";
                }
                if (props.pageContentType == PageContentType.DELIVERY) {
                    pageName = "DELIVERY";
                }

                Mixpanel.identify(response.id);
                Mixpanel.track("Accessed a Page", {
                    type: pageName,
                    project_token: props.currentProjectInfo?.unique_token,
                    team_subdomain: window.location.hostname.split(".")[0],
                });

                PlotAPI.getCurrentUserPic().then((response) => {
                    _setUserPic(response);
                });

                setUserTheme(response.user_preferences.theme);
            }
        });
    }, []);

    // User Info Changed?
    useEffect(() => {
        if (userInfo) {
            PlotAPI.getCurrentUsersTeams().then((response) => {
                setUsersTeams(response?.map((team) => new TeamInfo(team)) || []);
            });
        } else {
            setUsersTeams([]);
        }
    }, [userInfo]);

    /**
     * Updates User Info in the backend and then updates the context with it
     * @param userInfo New User Info to Update with
     * @returns boolean indicating success of the update
     */
    const setUserInfo = (userInfo): Promise<boolean> => {
        // TODO: Add some POST to update user info in backend, then get new data, and then call _setUserInfo
        return PlotAPI.putCurrentUserInfo(userInfo).then((response) => {
            if (response && response.message === "Success") {
                return PlotAPI.getCurrentUserInfo().then((response) => {
                    _setUserInfo(new UserInfo(response));

                    if (response) {
                        setLoggedIn(true);
                    } else {
                        setLoggedIn(false);
                    }

                    return true;
                });
            }

            notification.warning({
                message: "Cannot update User Info",
                description: response?.message,
                duration: globalAppConfig.NOTIFICATION_DURATION,
            });

            return false;
        });
    };

    /**
     * Set the current User's Profile Pic using a Data URL
     * @param imageAsDataURL URL containing the Data of the image to be uploaded
     * @returns boolean indicating success of the update
     */
    const setUserPic = (imageAsDataURL): Promise<boolean> => {
        return PlotAPI.postCurrentUserPic(imageAsDataURL).then((picUploadResponse) => {
            return PlotAPI.getCurrentUserPic().then((response) => {
                _setUserPic(response);

                return picUploadResponse;
            });
        });
    };

    const reloadUserInfo = (): Promise<boolean> => {
        return PlotAPI.getCurrentUserInfo().then((response) => {
            _setUserInfo(new UserInfo(response));
            return PlotAPI.getCurrentUserPic().then((response) => {
                _setUserPic(response);
                return Promise.resolve(true);
            });
        });
    };

    function setUserTheme(theme: string) {
        const body = document.querySelector("body");
        body?.setAttribute("data-theme", theme);
        // document.documentElement.classList.add("dark");
        localStorage.theme = theme;
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }

    const reloadUsersTeams = () => {
        PlotAPI.getCurrentUsersTeams().then((response) => {
            setUsersTeams(response?.map((team) => new TeamInfo(team)) || []);
        });
    };

    return {
        userInfo,
        gottenUserInfo,
        userPic,
        loggedIn,
        usersTeams,
        setUserInfo,
        setUserPic,
        reloadUserInfo,
        setUserTheme,
        setUsersTeams,
        reloadUsersTeams,
    };
};

export default useUserInfo;
