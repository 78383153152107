import { useEffect, useState } from "react";
import { Equipment } from "../models/Equipment";
import PlotAPI from "../plotAPI";

interface useNewEquipmentProps {
    projects: any[];
    projectInfo: (projectId: string | number) => any | null;
    overrideTimezone: string | null;
}

const useNewEquipment = (props: useNewEquipmentProps) => {
    const [newEquipmentByProject, setNewEquipmentByProject] = useState<
        { unique_token: string; equipment: Equipment[] }[]
    >([]);

    useEffect(() => {
        refreshNewEquipmentByProject();
    }, [props.projects]);

    useEffect(() => {
        const newNewEquipmentByProject = props.projects.map((project) => {
            const timezone = getTimezoneToUseForProject(project);

            const existingEquipmentForProject =
                newEquipmentByProject.find((p) => p.unique_token === project.unique_token)?.equipment || [];

            const newEquipmentForProject = existingEquipmentForProject.map((equipment) =>
                equipment.inAlternateTimezone(timezone)
            );

            return { unique_token: project.unique_token, equipment: newEquipmentForProject };
        });

        setNewEquipmentByProject(newNewEquipmentByProject);
    }, [props.overrideTimezone]);

    const getTimezoneToUseForProject = (project: any) => {
        return props.overrideTimezone || project.timezone;
    };

    const refreshNewEquipmentByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getEquipmentForProject(
                    project.unique_token,
                    project.claimed_by_team_subdomain || "projects",
                    getTimezoneToUseForProject(project)
                ).then((equipment) => {
                    return {
                        unique_token: project.unique_token,
                        equipment: equipment,
                    };
                })
            )
        ).then((equipment) => {
            setNewEquipmentByProject(equipment);
        });
    };

    const newEquipmentForProject = (projectId: string | number): Equipment[] => {
        const projectToken = props.projectInfo(projectId)?.unique_token;

        return (
            newEquipmentByProject.find((equipmentForProject) => equipmentForProject.unique_token === projectToken)
                ?.equipment || []
        );
    };

    return {
        newEquipmentByProject,
        newEquipmentForProject,
        refreshNewEquipmentByProject,
    };
};

export default useNewEquipment;
