import { useEffect, useState } from "react";
import { Delivery } from "../models/Delivery";
import PlotAPI from "../plotAPI";
import DeliveriesFilterInfo from "./deliveriesFilterinfo";

interface useDeliveriesProps {
    projects: any[];
    projectInfo: (projectId: string | number) => any | null;
    overrideTimezone: string | null;
}

const useDeliveries = (props: useDeliveriesProps) => {
    const [deliveriesFilterInfo, setDeliveriesFilterInfo] = useState<DeliveriesFilterInfo>(new DeliveriesFilterInfo());
    const [deliveriesByProject, setProjectsDeliveries] = useState<{ unique_token: string; deliveries: Delivery[] }[]>(
        []
    );

    useEffect(() => {
        refreshDeliveriesByProject();
    }, [props.projects, deliveriesFilterInfo]);

    useEffect(() => {
        const newDeliveriesByProject = props.projects.map((project) => {
            const timezone = getTimezoneToUseForProject(project);

            const existingDeliveriesForProject =
                deliveriesByProject.find((p) => p.unique_token === project.unique_token)?.deliveries || [];

            const newDeliveriesForProject = existingDeliveriesForProject.map((delivery) =>
                delivery.inAlternateTimezone(timezone)
            );

            return { unique_token: project.unique_token, deliveries: newDeliveriesForProject };
        });

        setProjectsDeliveries(newDeliveriesByProject);
    }, [props.overrideTimezone]);

    const getTimezoneToUseForProject = (project: any) => {
        return props.overrideTimezone || project.timezone;
    };

    const refreshDeliveriesByProject = (): Promise<void> => {
        return Promise.all(
            props.projects.map((project) =>
                PlotAPI.getProjectDeliveries(
                    project.claimed_by_team_subdomain || "projects",
                    project.unique_token,
                    deliveriesFilterInfo,
                    getTimezoneToUseForProject(project)
                ).then((deliveries) => {
                    return { unique_token: project.unique_token, deliveries };
                })
            )
        ).then((deliveries) => {
            setProjectsDeliveries(deliveries);
        });
    };

    const deliveriesForProject = (projectId: string | number): any[] => {
        const projectToken = props.projectInfo(projectId)?.unique_token;

        return deliveriesByProject.find((project) => project.unique_token === projectToken)?.deliveries || [];
    };

    return {
        deliveriesByProject,
        deliveriesForProject,
        refreshDeliveriesByProject,
        deliveriesFilterInfo,
        setDeliveriesFilterInfo,
    };
};

export default useDeliveries;
