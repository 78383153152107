const mapIcons = {
    equipmentIcons: [
        "barricade_black",
        "barricade_blue",
        "barricade_orange",
        "barricade_pink",
        "barricade_purple",
        "barricade_red",
        "barricade_turquoise",
        "barricade_yellow",
        "concrete_pump_black",
        "concrete_pump_blue",
        "concrete_pump_orange",
        "concrete_pump_pink",
        "concrete_pump_purple",
        "concrete_pump_red",
        "concrete_pump_turquoise",
        "concrete_pump_yellow",
        "crane_truck_black",
        "crane_truck_blue",
        "crane_truck_orange",
        "crane_truck_pink",
        "crane_truck_purple",
        "crane_truck_red",
        "crane_truck_turquoise",
        "crane_truck_yellow",
        "crawler_crane_black",
        "crawler_crane_blue",
        "crawler_crane_orange",
        "crawler_crane_pink",
        "crawler_crane_purple",
        "crawler_crane_red",
        "crawler_crane_turquoise",
        "crawler_crane_yellow",
        "fork_lift_black",
        "fork_lift_blue",
        "fork_lift_orange",
        "fork_lift_pink",
        "fork_lift_purple",
        "fork_lift_red",
        "fork_lift_turquoise",
        "fork_lift_yellow",
        "hook_black",
        "hook_blue",
        "hook_orange",
        "hook_pink",
        "hook_purple",
        "hook_red",
        "hook_turquoise",
        "hook_yellow",
        "office_trailer_black",
        "office_trailer_blue",
        "office_trailer_orange",
        "office_trailer_pink",
        "office_trailer_purple",
        "office_trailer_red",
        "office_trailer_turquoise",
        "office_trailer_yellow",
        "restroom_black",
        "restroom_blue",
        "restroom_orange",
        "restroom_pink",
        "restroom_purple",
        "restroom_red",
        "restroom_turquoise",
        "restroom_yellow",
        "skip_hoist_black",
        "skip_hoist_blue",
        "skip_hoist_orange",
        "skip_hoist_pink",
        "skip_hoist_purple",
        "skip_hoist_red",
        "skip_hoist_turquoise",
        "skip_hoist_yellow",
        "tower_crane_black",
        "tower_crane_blue",
        "tower_crane_orange",
        "tower_crane_pink",
        "tower_crane_purple",
        "tower_crane_red",
        "tower_crane_turquoise",
        "tower_crane_yellow",
    ],
    // standardIcons: [
    //     "barrier_std_icon",
    //     "exit_std_icon",
    //     "gate_std_icon",
    //     "jobsite_trailer_std_icon",
    //     "no-entry_std_icon",
    //     "portable_toilet_std_icon",
    // ],
    sdfIcons: [
        "arrow_sdf_icon",
        "left-chevron_sdf_icon",
        "left-double-chevron_sdf_icon",
        "right-chevron_sdf_icon",
        "right-double-chevron_sdf_icon",
        "two_way_sdf_icon_1",
        "two_way_sdf_icon_2",
        "barbed_wire_sdf_icon",
        "bullet_sdf_icon",
        "crane_sdf_icon",
        "delivery_truck_sdf_icon",
        "dumpster_sdf_icon",
        "lightning_sdf_icon",
        "map_marker_sdf_icon",
        "no-entry_sdf_icon",
        "no-parking_sdf_icon",
        "parking-sign_sdf_icon",
        "portable-toilet_sdf_icon",
        "road-barrier_sdf_icon",
        "warning_sdf_icon",
    ],
};

export default mapIcons;
