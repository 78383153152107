import { useEffect, useState } from "react";
import PageContentType from "../PageContentType";

interface useProjectInfoProps {
    projects: any[];
    currentProjectInfo: any | null;
    pageContentType: PageContentType | null;
}

const useActiveProjects = (props: useProjectInfoProps) => {
    const [currentActiveProjects, setCurrentActiveProjects] = useState<any[]>([]);

    useEffect(() => {
        if (props.pageContentType == PageContentType.PROJECT || props.pageContentType == PageContentType.DELIVERY) {
            setCurrentActiveProjects(props.currentProjectInfo ? [props.currentProjectInfo] : []);
        } else {
            setCurrentActiveProjects(props.projects);
        }
    }, [props.projects, props.pageContentType, props.currentProjectInfo]);

    return {
        currentActiveProjects,
    };
};

export default useActiveProjects;
